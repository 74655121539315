.revealing-modal-div{
    margin-right: 2vw;
}
.modal-bg{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    animation: fadeOut 0.4s;
}

.modal-content{
    position: fixed;
    top: 13vh;
    background-color: #FFFFFF;
    width: 100%;
    animation: slideIn 0.3s linear;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > *{
        margin-left: 5vw;
        margin-bottom: 2vh;
        margin-top: 2vh;
    }
}

.modal-button-div{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    border-radius: 100%;
}

@keyframes fadeOut{
    from{
        opacity: 0.6;
    }

    to{
        opacity: 1;
    }
}

@keyframes slideIn{
    0%{
        transform: translate(0, 100vh);
        opacity: 0
    }

    90%{
        transform: translate(0, -2vh);
        opacity: 1
    }

    100%{
        transform: translate(0, 0);
        opacity: 1
    }
}

@media only screen and (min-width: 720px){
    .revealing-modal-div{
        display:none;
    }
}