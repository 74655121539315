* {
  margin: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #0A6872;
  --dark-text-color: #555353;
  --light-text-color: #B0ADAD;
  --grey-background-color: #F0EEEF;
  --error-color: #f51818c1;
}

body {
  font-family: 'Poppins', sans-serif;
}