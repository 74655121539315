@keyframes slideIn{
    0%{
        transform: translate(0, 100%);
        opacity: 0
    }
    95%{
        transform: translate(0, -2vh);
        opacity: 1
    }
    100%{
        transform: translate(0, 0);
        opacity: 1
    }
}

.slide-in{
    animation: slideIn 0.3s linear;
}

.hidden{
    display: none;
}

.hover-in{
    display: block;
    animation: slideIn 0.3s linear;
    cursor: default;
}